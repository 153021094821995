* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.loader {
  height: 50vh;
}
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20vh;
}

.spinner {
  width: 80px;
  height: 80px;
  border: 8px solid rgba(255, 255, 255, 0.1);
  border-top: 8px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.text {
  position: absolute;
  font-size: 0.8em;
  color: #ffffff;
  font-family: Arial, sans-serif;
  text-align: center;
  line-height: 80px;
  width: 100%;
  height: 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
