/*-----------------------------------*\
  #style.css
\*-----------------------------------*/

/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {
  /**
     * colors
     */

  /* gradient */

  --bg-gradient-onyx: linear-gradient(
    to bottom right,
    hsl(240, 1%, 25%) 3%,
    hsl(0, 0%, 19%) 97%
  );
  --bg-gradient-jet: linear-gradient(
      to bottom right,
      hsla(240, 1%, 18%, 0.251) 0%,
      hsla(240, 2%, 11%, 0) 100%
    ),
    hsl(240, 2%, 13%);
  --bg-gradient-yellow-1: linear-gradient(
    to bottom right,
    hsl(45, 100%, 71%) 0%,
    hsla(36, 100%, 69%, 0) 50%
  );
  --bg-gradient-yellow-2: linear-gradient(
      135deg,
      hsla(45, 100%, 71%, 0.251) 0%,
      hsla(35, 100%, 68%, 0) 59.86%
    ),
    hsl(240, 2%, 13%);
  --border-gradient-onyx: linear-gradient(
    to bottom right,
    hsl(0, 0%, 25%) 0%,
    hsla(0, 0%, 25%, 0) 50%
  );
  --text-gradient-yellow: linear-gradient(
    to right,
    hsl(45, 100%, 72%),
    hsl(35, 100%, 68%)
  );

  /* solid */

  --jet: hsl(0, 0%, 22%);
  --onyx: hsl(240, 1%, 17%);
  --eerie-black-1: hsl(240, 2%, 13%);
  --eerie-black-2: hsl(240, 2%, 12%);
  --smoky-black: hsl(0, 0%, 7%);
  --white-1: hsl(0, 0%, 100%);
  --white-2: hsl(0, 0%, 98%);
  --orange-yellow-crayola: hsl(45, 100%, 72%);
  --vegas-gold: hsl(45, 54%, 58%);
  --light-gray: hsl(0, 0%, 84%);
  --light-gray-70: hsla(0, 0%, 84%, 0.7);
  --bittersweet-shimmer: hsl(0, 43%, 51%);

  /**
     * typography
     */

  /* font-family */
  --ff-poppins: "Poppins", sans-serif;

  /* font-size */
  --fs-1: 24px;
  --fs-2: 18px;
  --fs-3: 17px;
  --fs-4: 16px;
  --fs-5: 15px;
  --fs-6: 14px;
  --fs-7: 13px;
  --fs-8: 11px;

  /* font-weight */
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;

  /**
     * shadow
     */

  --shadow-1: -4px 8px 24px hsla(0, 0%, 0%, 0.25);
  --shadow-2: 0 16px 30px hsla(0, 0%, 0%, 0.25);
  --shadow-3: 0 16px 40px hsla(0, 0%, 0%, 0.25);
  --shadow-4: 0 25px 50px hsla(0, 0%, 0%, 0.15);
  --shadow-5: 0 24px 80px hsla(0, 0%, 0%, 0.25);

  /**
     * transition
     */

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease-in-out;
}

/*-----------------------------------*\
    #RESET
  \*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

img,
ion-icon,
a,
button,
time,
span {
  display: block;
}

button {
  font: inherit;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

input,
textarea {
  display: block;
  width: 100%;
  background: none;
  font: inherit;
}

::selection {
  background: var(--orange-yellow-crayola);
  color: var(--smoky-black);
}

:focus {
  outline-color: var(--orange-yellow-crayola);
}

html {
  font-family: var(--ff-poppins);
}

body {
  background: var(--smoky-black);
}

/*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/

.sidebar,
article {
  background: var(--eerie-black-2);
  border: 1px solid var(--jet);
  border-radius: 20px;
  padding: 15px;
  box-shadow: var(--shadow-1);
  z-index: 1;
}

.separator {
  width: 100%;
  height: 1px;
  background: var(--jet);
  margin: 16px 0;
}

.icon-box {
  position: relative;
  background: var(--border-gradient-onyx);
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: var(--orange-yellow-crayola);
  box-shadow: var(--shadow-1);
  z-index: 1;
}

.icon-box::before {
  content: "";
  position: absolute;
  inset: 1px;
  background: var(--eerie-black-1);
  border-radius: inherit;
  z-index: -1;
}

.icon-box ion-icon {
  --ionicon-stroke-width: 35px;
}

article {
  display: block;
  animation: fade 0.5s ease backwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.h2,
.h3,
.h4,
.h5 {
  color: var(--white-2);
  text-transform: capitalize;
}

.h2 {
  font-size: var(--fs-1);
}

.h3 {
  font-size: var(--fs-2);
}

.h4 {
  font-size: var(--fs-4);
}

.h5 {
  font-size: var(--fs-7);
  font-weight: var(--fw-500);
}

.article-title {
  position: relative;
  padding-bottom: 7px;
}

.article-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 3px;
  background: var(--text-gradient-yellow);
  border-radius: 3px;
}

.has-scrollbar::-webkit-scrollbar {
  width: 5px; /* for vertical scrollbar */
  height: 5px; /* for horizontal scrollbar */
}

.has-scrollbar::-webkit-scrollbar-track {
  background: var(--onyx);
  border-radius: 5px;
}

.has-scrollbar::-webkit-scrollbar-thumb {
  background: var(--orange-yellow-crayola);
  border-radius: 5px;
}

.has-scrollbar::-webkit-scrollbar-button {
  width: 20px;
}

.content-card {
  position: relative;
  background: var(--border-gradient-onyx);
  padding: 15px;
  padding-top: 45px;
  border-radius: 14px;
  box-shadow: var(--shadow-2);
  cursor: pointer;
  z-index: 1;
}

.content-card::before {
  content: "";
  position: absolute;
  inset: 1px;
  background: var(--bg-gradient-jet);
  border-radius: inherit;
  z-index: -1;
}

/*-----------------------------------*\
    #MAIN
  \*-----------------------------------*/

main {
  margin: 15px 12px;
  margin-bottom: 75px;
  min-width: 259px;
}

/*-----------------------------------*\
    #RESPONSIVE
  \*-----------------------------------*/

/**
   * responsive larger than 580px screen
   */

@media (min-width: 580px) {
  /**
     * CUSTOM PROPERTY
     */

  :root {
    /**
       * typography
       */

    --fs-1: 32px;
    --fs-2: 24px;
    --fs-3: 26px;
    --fs-4: 18px;
    --fs-6: 15px;
    --fs-7: 15px;
    --fs-8: 12px;
  }

  /**
     * #REUSED STYLE
     */

  .sidebar,
  article {
    width: 520px;
    margin-inline: auto;
    padding: 30px;
  }

  .article-title {
    font-weight: var(--fw-600);
    padding-bottom: 15px;
  }

  .article-title::after {
    width: 40px;
    height: 5px;
  }

  .icon-box {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    font-size: 18px;
  }

  /**
     * #MAIN
     */

  main {
    margin-top: 60px;
    margin-bottom: 100px;
  }
}

/**
   * responsive larger than 768px screen
   */

@media (min-width: 768px) {
  /**
     * REUSED STYLE
     */

  .sidebar,
  article {
    width: 700px;
  }

  .has-scrollbar::-webkit-scrollbar-button {
    width: 100px;
  }
}

/**
   * responsive larger than 1024px screen
   */

@media (min-width: 1024px) {
  /**
     * CUSTOM PROPERTY
     */

  :root {
    /**
      * shadow
      */

    --shadow-1: -4px 8px 24px hsla(0, 0%, 0%, 0.125);
    --shadow-2: 0 16px 30px hsla(0, 0%, 0%, 0.125);
    --shadow-3: 0 16px 40px hsla(0, 0%, 0%, 0.125);
  }

  /**
     * REUSED STYLE
     */

  .sidebar,
  article {
    width: 950px;
    box-shadow: var(--shadow-5);
  }

  /**
     * MAIN 
     */

  main {
    margin-bottom: 60px;
  }

  .main-content {
    position: relative;
    width: max-content;
    margin: auto;
  }
}

/**
   * responsive larger than 1250px screen
   */

@media (min-width: 1250px) {
  /**
     * RESET
     */

  body::-webkit-scrollbar {
    width: 20px;
  }

  body::-webkit-scrollbar-track {
    background: var(--smoky-black);
  }

  body::-webkit-scrollbar-thumb {
    border: 5px solid var(--smoky-black);
    background: hsla(0, 0%, 100%, 0.1);
    border-radius: 20px;
    box-shadow: inset 1px 1px 0 hsla(0, 0%, 100%, 0.11),
      inset -1px -1px 0 hsla(0, 0%, 100%, 0.11);
  }

  body::-webkit-scrollbar-thumb:hover {
    background: hsla(0, 0%, 100%, 0.15);
  }

  body::-webkit-scrollbar-button {
    height: 60px;
  }

  /**
     * REUSED STYLE
     */

  .sidebar,
  article {
    width: auto;
  }

  article {
    min-height: 100%;
  }

  /**
     * MAIN
     */

  main {
    max-width: 1200px;
    margin-inline: auto;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 25px;
  }

  .main-content {
    min-width: 75%;
    width: 75%;
    margin: 0;
  }
}
