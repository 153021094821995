/*-----------------------------------*\
    #RESUME
  \*-----------------------------------*/

  .article-title {
    margin-bottom: 30px;
  }
  
/**
   * education and experience 
   */

   .timeline {
    margin-bottom: 30px;
  }
  
  .timeline .title-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 25px;
  }
  
  .timeline-list {
    font-size: var(--fs-6);
    margin-left: 45px;
  }
  
  .timeline-item {
    position: relative;
  }
  
  .timeline-item:not(:last-child) {
    margin-bottom: 20px;
  }
  
  .timeline-item-title {
    font-size: var(--fs-6);
    line-height: 1.3;
    margin-bottom: 7px;
  }
  
  .timeline-list span {
    color: var(--vegas-gold);
    font-weight: var(--fw-400);
    line-height: 1.6;
  }
  
  .timeline-item:not(:last-child)::before {
    content: "";
    position: absolute;
    top: -25px;
    left: -30px;
    width: 1px;
    height: calc(100% + 50px);
    background: var(--jet);
  }
  
  .timeline-item::after {
    content: "";
    position: absolute;
    top: 5px;
    left: -33px;
    height: 6px;
    width: 6px;
    background: var(--text-gradient-yellow);
    border-radius: 50%;
    box-shadow: 0 0 0 4px var(--jet);
  }
  
  .timeline-text {
    color: var(--light-gray);
    font-weight: var(--fw-300);
    line-height: 1.6;
  }
  
  /**
     * skills 
     */
  
  .skills-title {
    margin-bottom: 20px;
  }
  
  .skills-list {
    padding: 20px;
  }
  
  .skills-item:not(:last-child) {
    margin-bottom: 15px;
  }
  
  .skill .title-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 8px;
  }
  
  .skill .title-wrapper data {
    color: var(--light-gray);
    font-size: var(--fs-7);
    font-weight: var(--fw-300);
  }
  
  .skill-progress-bg {
    background: var(--jet);
    width: 100%;
    height: 8px;
    border-radius: 10px;
  }
  
  .skill-progress-fill {
    background: var(--text-gradient-yellow);
    height: 100%;
    border-radius: inherit;
  }




/**
   * responsive larger than 580px screen
   */

@media (min-width: 580px) {
  
    /**
       * #RESUME
       */
  
    .timeline-list {
      margin-left: 65px;
    }
  
    .timeline-item:not(:last-child)::before {
      left: -40px;
    }
  
    .timeline-item::after {
      height: 8px;
      width: 8px;
      left: -43px;
    }
  
    .skills-item:not(:last-child) {
      margin-bottom: 25px;
    }
  

  }

  
  /**
   * responsive larger than 1250px screen
   */

@media (min-width: 1250px) {
    /**
         * RESUME
         */
  
    .timeline-text {
      max-width: 700px;
    }
  }