/*-----------------------------------*\
    #SIDEBAR
  \*-----------------------------------*/

.sidebar {
  margin-bottom: 15px;
  max-height: 405px;
  overflow: hidden;
  transition: var(--transition-2);
}

.sidebar-info {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.avatar-box {
  background: var(--bg-gradient-onyx);
  border-radius: 20px;
}

.info-content .name {
  color: var(--white-2);
  font-size: var(--fs-3);
  font-weight: var(--fw-500);
  letter-spacing: -0.25px;
  margin-bottom: 10px;
}

.info-content .title {
  color: var(--white-1);
  background: var(--onyx);
  font-size: var(--fs-8);
  font-weight: var(--fw-300);
  width: max-content;
  padding: 3px 12px;
  border-radius: 8px;
}

.info_more-btn {
  position: absolute;
  top: -15px;
  right: -15px;
  border-radius: 0 15px;
  font-size: 13px;
  color: var(--orange-yellow-crayola);
  background: var(--border-gradient-onyx);
  padding: 10px;
  box-shadow: var(--shadow-2);
  transition: var(--transition-1);
  z-index: 1;
}

.info_more-btn::before {
  content: "";
  position: absolute;
  inset: 1px;
  border-radius: inherit;
  background: var(--bg-gradient-jet);
  transition: var(--transition-1);
  z-index: -1;
}

.info_more-btn:hover,
.info_more-btn:focus {
  background: var(--bg-gradient-yellow-1);
}

.info_more-btn:hover::before,
.info_more-btn:focus::before {
  background: var(--bg-gradient-yellow-2);
}

.info_more-btn span {
  display: none;
}

.sidebar-info_more {
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
  transition: var(--transition-2);
}

.sidebar-info_more.show {
  opacity: 1;
  visibility: visible;
  max-height: 500px;
}

.contacts-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

.contact-item {
  min-width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.contact-info {
  max-width: calc(100% - 46px);
  width: calc(100% - 46px);
}

.contact-title {
  color: var(--light-gray-70);
  font-size: var(--fs-8);
  text-transform: uppercase;
  margin-bottom: 2px;
}

.contact-info :is(.contact-link, time, address) {
  color: var(--white-2);
  font-size: var(--fs-7);
}

.contact-info address {
  font-style: normal;
}

.social-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding-bottom: 4px;
  padding-left: 7px;
}

.social-item .social-link {
  color: var(--light-gray-70);
  font-size: 18px;
}

.social-item .social-link:hover {
  color: var(--light-gray);
}

/*-----------------------------------*\
    #RESPONSIVE
  \*-----------------------------------*/

/**
   * responsive larger than 580px screen
   */

@media (min-width: 580px) {
  /**
       * #SIDEBAR
       */

  .sidebar {
    max-height: 584px;
    margin-bottom: 30px;
  }

  .sidebar-info {
    gap: 25px;
  }

  .avatar-box {
    border-radius: 30px;
  }

  .avatar-box img {
    width: 120px;
  }

  .info-content .name {
    margin-bottom: 15px;
  }

  .info-content .title {
    padding: 5px 18px;
  }

  .info_more-btn {
    top: -30px;
    right: -30px;
    padding: 10px 15px;
  }

  .info_more-btn span {
    display: block;
    font-size: var(--fs-8);
  }

  .info_more-btn ion-icon {
    display: none;
  }

  .separator {
    margin: 32px 0;
  }

  .contacts-list {
    gap: 20px;
  }

  .contact-info {
    max-width: calc(100% - 64px);
    width: calc(100% - 64px);
  }
}

/**
   * responsive larger than 768px screen
   */

@media (min-width: 768px) {
  /**
       * SIDEBAR
       */

  .contacts-list {
    grid-template-columns: 1fr 1fr;
    gap: 30px 15px;
  }
}

/**
   * responsive larger than 1250px screen
   */

@media (min-width: 1250px) {
  /**
       * SIDEBAR
       */

  .sidebar {
    position: sticky;
    top: 60px;
    max-height: max-content;
    height: 100%;
    margin-bottom: 0;
    padding-top: 60px;
    z-index: 1;
  }

  .sidebar-info {
    flex-direction: column;
  }

  .avatar-box img {
    width: 150px;
  }

  .info-content .name {
    white-space: nowrap;
    text-align: center;
  }

  .info-content .title {
    margin: auto;
  }

  .info_more-btn {
    display: none;
  }

  .sidebar-info_more {
    opacity: 1;
    visibility: visible;
  }

  .contacts-list {
    grid-template-columns: 1fr;
  }

  .contact-info :is(.contact-link) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .contact-info :is(.contact-link, time, address) {
    --fs-7: 14px;
    font-weight: var(--fw-300);
  }

  .separator:last-of-type {
    margin: 15px 0;
    opacity: 0;
  }

  .social-list {
    justify-content: center;
  }
}
