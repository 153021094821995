/*-----------------------------------*\
    #PORTFOLIO
  \*-----------------------------------*/

.filter-list {
  display: none;
}

.filter-select-box {
  position: relative;
  margin-bottom: 25px;
}

.filter-select {
  background: var(--eerie-black-2);
  color: var(--light-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid var(--jet);
  border-radius: 14px;
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
}

.filter-select .select-icon {
  transform: rotate(0.5turn);
}

.select-list {
  background: var(--eerie-black-2);
  position: absolute;
  top: calc(100% + 6px);
  width: 100%;
  padding: 6px;
  border: 1px solid var(--jet);
  border-radius: 14px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.15s ease-in-out;
}

.filter-select + .select-list {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.select-item button {
  background: var(--eerie-black-2);
  color: var(--light-gray);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
  text-transform: capitalize;
  width: 100%;
  padding: 8px 10px;
  border-radius: 8px;
}

.select-item button:hover {
  --eerie-black-2: hsl(240, 2%, 20%);
}

.project-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  margin-bottom: 10px;
}

.project-item {
  display: block;
  animation: scaleUp 0.25s ease forwards;
}

@keyframes scaleUp {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.project-item > a {
  width: 100%;
}

.project-img {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 15px;
}

.project-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
  transition: var(--transition-1);
}

.project-item > a:hover .project-img::before {
  background: hsla(0, 0%, 0%, 0.5);
}

.project-item-icon-box {
  --scale: 0.8;

  background: var(--jet);
  color: var(--orange-yellow-crayola);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(var(--scale));
  font-size: 20px;
  padding: 18px;
  border-radius: 12px;
  opacity: 0;
  z-index: 1;
  transition: var(--transition-1);
}

.project-item > a:hover .project-item-icon-box {
  --scale: 1;
  opacity: 1;
}

.project-item-icon-box ion-icon {
  --ionicon-stroke-width: 50px;
}

.project-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition-1);
}

.project-item > a:hover img {
  transform: scale(1.1);
}

.project-title,
.project-category {
  margin-left: 10px;
}

.project-title {
  color: var(--white-2);
  font-size: var(--fs-5);
  font-weight: var(--fw-400);
  text-transform: capitalize;
  line-height: 1.3;
}

.project-category {
  color: var(--light-gray-70);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
}

/*-----------------------------------*\
      #PROJECT
    \*-----------------------------------*/

.project-posts {
  margin-bottom: 10px;
}

.project-posts-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.project-post-item > a {
  position: relative;
  background: var(--border-gradient-onyx);
  height: 100%;
  box-shadow: var(--shadow-4);
  border-radius: 16px;
  z-index: 1;
}

.project-post-item > a::before {
  content: "";
  position: absolute;
  inset: 1px;
  border-radius: inherit;
  background: var(--eerie-black-1);
  z-index: -1;
}

.project-banner-box {
  width: 100%;
  height: 200px;
  border-radius: 12px;
  overflow: hidden;
}

.project-banner-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition-1);
}

.project-post-item > a:hover .project-banner-box img {
  transform: scale(1.1);
}

.project-content {
  padding: 15px;
}

.project-meta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  margin-bottom: 10px;
}

.project-meta :is(.project-category, time) {
  color: var(--light-gray-70);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
}

.project-meta .dot {
  background: var(--light-gray-70);
  width: 4px;
  height: 4px;
  border-radius: 4px;
}

.project-item-title {
  margin-bottom: 10px;
  line-height: 1.3;
  transition: var(--transition-1);
}

.project-post-item > a:hover .project-item-title {
  color: var(--orange-yellow-crayola);
}

.project-text {
  color: var(--light-gray);
  font-size: var(--fs-6);
  font-weight: var(--fw-300);
  line-height: 1.6;
}

/*-----------------------------------*\
    #RESPONSIVE
  \*-----------------------------------*/

/**
   * responsive larger than 450px screen
   */
@media (min-width: 450px) {
  /**
       * #PORTFOLIO, PROJECT 
       */

  .project-img,
  .project-banner-box {
    height: auto;
  }
}

/**
   * responsive larger than 580px screen
   */

@media (min-width: 580px) {
  /**
       * #PORTFOLIO, PROJECT
       */

  .project-img,
  .project-banner-box {
    border-radius: 16px;
  }

  .project-posts-list {
    gap: 30px;
  }

  .project-content {
    padding: 25px;
  }
}

/**
   * responsive larger than 768px screen
   */

@media (min-width: 768px) {
  /**
       * PORTFOLIO
       */

  .article-title {
    padding-bottom: 20px;
  }

  .filter-select-box {
    display: none;
  }

  .filter-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    padding-left: 5px;
    margin-bottom: 30px;
  }

  .filter-item button {
    color: var(--light-gray);
    font-size: var(--fs-5);
    transition: var(--transition-1);
  }

  .filter-item button:hover {
    color: var(--light-gray-70);
  }

  .filter-item button.active {
    color: var(--orange-yellow-crayola);
  }

  /* portfolio and project grid */

  .project-list,
  .project-posts-list {
    grid-template-columns: 1fr 1fr;
  }
}

/**
   * responsive larger than 1024px screen
   */

@media (min-width: 1024px) {
  /**
       * PORTFOLIO
       */

  .project-list {
    grid-template-columns: repeat(3, 1fr);
  }

  /**
       * PROJECT
       */

  .project-banner-box {
    height: 230px;
  }
}
